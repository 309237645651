var ConcertoEspagne;

import {
  extraErrorDataIntegration,
  contextLinesIntegration
} from '@sentry/integrations';

ConcertoEspagne = (function() {
  class ConcertoEspagne {
    //################
    // Class methods #
    //################
    static load_theme() {
      ConcertoEspagne.log_start();
      ConcertoEspagne.init_sentry();
      ConcertoEspagne.load_fancybox();
      ConcertoEspagne.load_stellar();
      ConcertoEspagne.fix_page_height();
      ConcertoEspagne.scroll_window();
      ConcertoEspagne.content_way_point();
      ConcertoEspagne.translate_flag_links();
      ConcertoEspagne.load_go_here();
      ConcertoEspagne.load_alerts();
      ConcertoEspagne.resize_images_container();
      ConcertoEspagne.call_google();
      return ConcertoEspagne.log_end();
    }

    static log_start() {
      return this.debug(`start loading page ${window.location.pathname}`);
    }

    static log_end() {
      return this.debug(`end loading page ${window.location.pathname}`);
    }

    static init_sentry() {
      if (ConcertoEspagne.sentry_loaded === true) {
        return this.debug(" * sentry is already loaded");
      } else {
        this.debug("* loading sentry");
        window.Sentry.init({
          dsn: $('#sentry-data').data('dsn'),
          release: $('#sentry-data').data('release'),
          environment: $('#sentry-data').data('environment'),
          ignoreErrors: $('#sentry-data').data('ignore-errors'),
          denyUrls: $('#sentry-data').data('deny-urls').map(function(url) {
            return new RegExp(url, 'i');
          }),
          tracesSampleRate: +($('#sentry-data').data('traces-sample-rate')),
          replaysSessionSampleRate: +($('#sentry-data').data('replays-session-sample-rate')),
          replaysOnErrorSampleRate: +($('#sentry-data').data('replays-on-error-sample-rate')),
          integrations: [window.Sentry.browserTracingIntegration(), window.Sentry.replayIntegration(), extraErrorDataIntegration(), contextLinesIntegration()]
        });
        return ConcertoEspagne.sentry_loaded = true;
      }
    }

    static load_fancybox() {
      this.debug("* loading fancybox");
      window.Fancybox.openers = new Map();
      return window.Fancybox.bind('[data-fancybox]', {
        Images: {
          protected: true
        }
      });
    }

    static load_stellar() {
      this.debug("* loading stellar");
      if (ConcertoEspagne.stellar_loaded === true) {
        $.stellar('destroy');
      }
      $.stellar({
        responsive: true,
        parallaxBackgrounds: true,
        parallaxElements: true,
        horizontalScrolling: false,
        hideDistantElements: false,
        scrollProperty: 'scroll'
      });
      return ConcertoEspagne.stellar_loaded = true;
    }

    static fix_page_height() {
      var currentPad, newPad;
      this.debug("* loading fix_page_height");
      if ($('nav').hasClass('overlay-bar') || $('nav').hasClass('contained-bar')) {
        currentPad = parseInt($('.main-container').find(':first-child').css('padding-top'));
        newPad = currentPad + $('nav').outerHeight() - 48;
        if (currentPad > 0) {
          return $('.main-container').children(':first').css('padding-top', newPad);
        }
      }
    }

    static scroll_window() {
      this.debug("* loading scroll_window");
      if ($(window).scrollTop() > 500 && !$('.mobile-toggle').is(':visible')) {
        return $('.top-bar').addClass('nav-sticky');
      } else {
        return $('.top-bar').removeClass('nav-sticky');
      }
    }

    static content_way_point() {
      this.debug("* loading content_way_point");
      $('.ftco-animate').waypoint((function(direction) {
        if (direction === 'down' && !$(this.element).hasClass('ftco-animated')) {
          $(this.element).addClass('item-animate');
          setTimeout((function() {
            $('body .ftco-animate.item-animate').each(function(k) {
              var el;
              el = $(this);
              setTimeout((function() {
                var effect;
                effect = el.data('animate-effect');
                if (effect === 'fadeIn') {
                  el.addClass('fadeIn ftco-animated');
                } else if (effect === 'fadeInLeft') {
                  el.addClass('fadeInLeft ftco-animated');
                } else if (effect === 'fadeInRight') {
                  el.addClass('fadeInRight ftco-animated');
                } else {
                  el.addClass('fadeInUp ftco-animated');
                }
                el.removeClass('item-animate');
              }), k * 50, 'easeInOutExpo');
            });
          }), 100);
        }
      }), {
        offset: '95%'
      });
    }

    static translate_flag_links() {
      var data, default_locale, object, skip_locale;
      this.debug("* loading translate_flag_links");
      if ($('[data-toggle=lang-link]').length > 0) {
        object = $($('[data-toggle=lang-link]')[0]);
        data = object.data();
        default_locale = data['defaultLocale'];
        skip_locale = data['skipLocale'];
        return $('.lang-link').each(function() {
          var current_url, key, locale, new_url, url;
          locale = $(this).data('locale');
          key = locale + 'Slug';
          url = data[key];
          if (locale === default_locale) {
            new_url = '/' + url;
            return $(this).attr('href', new_url);
          } else if (locale === skip_locale) {
            current_url = $(this).attr('href');
            new_url = current_url + '/' + url;
            return $(this).attr('href', new_url);
          } else {
            new_url = '/' + locale + '/' + url;
            return $(this).attr('href', new_url);
          }
        });
      }
    }

    static load_go_here() {
      this.debug("* loading go_here");
      $('.mouse-icon').on('click', function(event) {
        event.preventDefault();
        $('html,body').animate({
          scrollTop: $('.goto-here').offset().top
        }, 500, 'easeInOutExpo');
        return false;
      });
    }

    static load_alerts() {
      this.debug("* loading alerts");
      return $('.alert:not(.dont-dismiss)').delay(3000).slideUp(200, function() {
        return $(this).alert('close');
      });
    }

    static resize_images_container() {
      this.debug("* loading resize_images_container");
      $('.background-image-holder').each(function() {
        var img_src;
        img_src = $(this).children('img').attr('src');
        $(this).css('background', 'url("' + img_src + '")');
        $(this).children('img').hide();
        return $(this).css('background-position', '50% 0%');
      });
      $('.parallax-image-holder').each(function() {
        var container, img, src, ws;
        img = $(this).children('img');
        src = img.attr('src');
        if (src != null) {
          ws = new RegExp(' ', 'gi');
          container = $(this);
          src = src.replace(ws, '%20');
          img.remove();
          return container.css({
            'background-image': 'url("' + src + '")',
            'background-size': 'cover',
            'background-position': '50% 0',
            'background-repeat': 'no-repeat'
          });
        }
      });
      $('.foreground-image-holder').each(function() {
        var container, img, src, ws;
        img = $(this).children('img');
        src = img.attr('src');
        if (src != null) {
          ws = new RegExp(' ', 'gi');
          container = $(this);
          src = src.replace(ws, '%20');
          img.remove();
          return container.css({
            'background-image': 'url("' + src + '")',
            'background-size': 'cover',
            'background-position': 'center center',
            'background-repeat': 'no-repeat'
          });
        }
      });
      $('.width33').css({
        height: $('#images-container').width() * 0.33
      });
      return $('.width67').css({
        height: $('#images-container').width() * 0.67
      });
    }

    static call_google() {
      this.debug("* loading call_google");
      if (typeof ga !== "undefined" && ga !== null) {
        ga('send', 'pageview', window.location.pathname);
        return this.debug("  GA called");
      } else {
        return this.debug("  GA not found");
      }
    }

    static debug(message) {
      return this._debug(`[ConcertoEspagne]: ${message}`);
    }

    static _debug(message) {
      var debug;
      debug = $('body').data('js-debug');
      if (debug) {
        return console.debug(message);
      }
    }

  };

  //###################
  // Class attributes #
  //###################
  ConcertoEspagne.stellar_loaded = false;

  ConcertoEspagne.sentry_loaded = false;

  return ConcertoEspagne;

}).call(this);

export default ConcertoEspagne;

// Load theme
$(document).on('turbo:load', ConcertoEspagne.load_theme);

$(window).on('scroll', function() {
  return ConcertoEspagne.scroll_window();
});

$(window).on('resize', function() {
  return ConcertoEspagne.resize_images_container();
});

$(document).on('click', 'div.mobile-toggle', function(_e) {
  return $('nav').toggleClass('open-nav');
});

$(document).on('click', 'a.history-back', function(e) {
  e.preventDefault();
  return window.history.back(-1);
});

$(document).on('mouseover', 'a.history-back', function(e) {
  return e.target.style.cursor = 'pointer';
});

// Kaminari "gap" links
$(document).on('click', 'a.kaminari-gap', function(e) {
  return e.preventDefault();
});
